class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        // {
        //   label: this.$t('page.banner'),
        //   icon: 'fa fa-info-circle',
        //   group: [
        //     {
        //       label: this.$t('page.banner'),
        //       route: {
        //         name: 'banner',
        //       },
        //     }
        //   ],
        // },
      ],
    }
  }
}

export default () => (new menu()).get()
